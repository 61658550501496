import local from './local.json';
import dev from './dev.json';

interface IConfig {
  engineName: string;
  endpointBase: string;
  searchKey: string;
  resultFields: {
    [key: string]: {
      raw: object;
      snippet: {
        size: number;
        fallback: boolean;
      };
    };
  };
  sortFields: Array<{
    name: string;
    value: string;
    direction: string;
  }>;
  disjunctiveFacets: string[];
  facets: {
    [key: string]: {
      type: string;
      size: number;
    };
  };
  titleField: string;
  urlField: string;
  dataopsOAuthClient: {
    clientId: string;
    authorizationEndpoint: string;
    tokenEndpoint: string;
    redirectUri: string;
    scope: string;
    decodeToken: boolean;
    redirectCallbackPath: string;
    autoLogin: boolean;
  };
  dataopsliveBaseUrl: string;
  dataopsCatalogApiDeployEndpoint: string;
  dataopsCatalogApiUpdateEndpoint: string;
  dataopsCatalogApiDeleteEndpoint: string;
  dataopsCreateShareLinkEndpoint: string;
  dataopsShareEnvironment: ShareEnvironment;
}

export function getConfig(): IConfig {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return local as IConfig;
    case 'dev':
      return dev as IConfig;
    // case 'qa':
    //   return qa as IConfig;
    // case 'production':
    //   return prod as IConfig;
    default:
      throw new Error(`No config for environment "REACT_APP_ENV"`);
  }
}

export function getFacetFields() {
  return Object.keys(getConfig().facets);
}
type ShareEnvironment = 'dev' | 'qa' | 'production';
export function resolveShareUrlDomain(shareEnvironment: ShareEnvironment): string {
  switch (shareEnvironment) {
    case 'dev':
      return 'https://share.qa.dataops.live';
    case 'qa':
      return 'https://share.qa.dataops.live';
    case 'production':
      return 'https://share.dataops.live';
  }
}

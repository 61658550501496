import type { IDeployment, IJob, IPipeline } from '../reducers/deploymentsSlice';

export const REPO_FILE_VALIDATE_CI_FILE_NAME = 'validate-ci.yml';
export const PIPELINE_JOB_NAME_VALIDATION = 'Test';
export const PIPELINE_JOB_NAME_MAIN = 'Set Up Redshift';

export function getValidationPipeline(pipelines: IPipeline[] | undefined): IPipeline | undefined {
  /**
   * Find the validation pipeline.
   *
   * The validation pipeline is the one that contains a job with the name
   * "Test all Models".
   */
  const validationPipeline = pipelines?.find((pipeline) => {
    const validationJob = pipeline.jobs.find((job) => {
      if (job.name.toLowerCase().includes(PIPELINE_JOB_NAME_VALIDATION.toLowerCase())) {
        return true;
      }
      return false;
    });
    if (validationJob !== undefined) {
      return true;
    }
    return false;
  });
  return validationPipeline;
}

export function getMainPipeline(
  pipelines: IPipeline[] | undefined,
  pipelineJobNameMain?: string | undefined,
): IPipeline | undefined {
  /**
   * Find the main pipeline.
   *
   * The main pipeline is the one that contains a job with the name
   * "Set Up Snowflake".
   */
  if (pipelines === undefined) {
    return undefined;
  }
  const jobName = pipelineJobNameMain ?? PIPELINE_JOB_NAME_MAIN;
  const mainPipeline = pipelines.find((pipeline) => {
    const mainJob = pipeline.jobs.find((job) => {
      if (job.name.toLowerCase().includes(jobName.toLowerCase())) {
        return true;
      }
      return false;
    });
    if (mainJob !== undefined) {
      return true;
    }
    return false;
  });
  return mainPipeline;
}

export function isValidateCiFileExists(projectInstance: IDeployment): boolean {
  // TODO: Check if the file exists in the repo
  // https://docs.gitlab.com/ee/api/repository_files.html
  return true;
}

interface IJobGroup {
  name: string;
  jobs: IPipeline['jobs'];
}

export function isPipelineSuccess(pipeline: IPipeline): boolean {
  let isPipelineSuccess = true;

  // loop over each pipeline job and group by name,
  // then check the statuses of each job in each group,
  // and return success if any jobs are successful
  const jobGroups = pipeline.jobs.reduce((groups: IJobGroup[], job) => {
    const foundGroup = groups.find((group) => group.name === job.name);
    if (foundGroup !== undefined) {
      foundGroup.jobs.push(job);
    } else {
      groups.push({
        name: job.name,
        jobs: [job],
      });
    }
    return groups;
  }, []);

  jobGroups.forEach((jobGroup) => {
    let isJobGroupSuccess = false;
    jobGroup.jobs.forEach((job) => {
      if (job.status === 'SUCCESS' || job.status === 'MANUAL') {
        isJobGroupSuccess = true;
      }
    });

    if (!isJobGroupSuccess) {
      isPipelineSuccess = false;
    }
  });

  return isPipelineSuccess;
}

export function isPipelineActive(pipeline: IPipeline): boolean {
  let isPipelineActive = false;
  pipeline.jobs.forEach((job) => {
    if (
      job.status === 'RUNNING' ||
      job.status === 'PENDING' ||
      job.status === 'PREPARING' ||
      job.status === 'SCHEDULED' ||
      job.status === 'CREATED' ||
      job.status === 'WAITING_FOR_RESOURCE'
    ) {
      isPipelineActive = true;
    }
  });
  return isPipelineActive;
}

export function getJobByNames(pipeline: IPipeline, jobNames: string[]): IJob | undefined {
  return pipeline.jobs.find((job) => jobNames.includes(job.name));
}

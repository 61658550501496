import { getConfig } from '../config/config-helper';

export function sendMetrics(analyticsData: any) {
  const { dataopsShareEnvironment } = getConfig();
  const enrichedAnalyticsData = {
    ...analyticsData,
    properties: {
      ...analyticsData.properties,
      environment: dataopsShareEnvironment,
    },
  };
  const analyticsUrl = 'https://ms5iwqbfij.execute-api.eu-west-2.amazonaws.com/metric';
  console.log('Sending metrics to ', analyticsUrl, enrichedAnalyticsData);
  // fetch(analyticsUrl, {
  //   method: 'POST',
  //   mode: 'no-cors',
  //   credentials: 'same-origin',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(enrichedAnalyticsData),
  // }).catch(() => {});
}

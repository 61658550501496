import React from 'react';
import TagSection from './TagSection';

interface ProjectCardBodyProps {
  description: string;
  verticals: string[];
  services: string[];
}

export default function ProjectCardBody({ description, verticals, services }: ProjectCardBodyProps) {
  return (
    <div id="details" className="flex flex-col grow space-y-4">
      <div>
        <dl className="w-auto">
          <dt className="text-sm font-medium text-gray-500">Description</dt>
          <dd className="text-sm text-gray-900">{description}</dd>
        </dl>
      </div>
      <div className="flex flex-row gap-2">
        <dl className="basis-1/2">
          <dt className="text-sm font-medium text-gray-500">Verticals</dt>
          <dd className="text-sm text-gray-900">
            <TagSection tags={verticals ?? []} />
          </dd>
        </dl>
        <dl className="basis-1/2">
          <dt className="text-sm font-medium text-gray-500">Services</dt>
          <dd className="text-sm text-gray-900">
            <TagSection tags={services ?? []} />
          </dd>
        </dl>
      </div>
    </div>
  );
}

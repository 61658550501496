import React, { useContext, useEffect, useRef, useState } from 'react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import type { SearchResult } from '@elastic/search-ui';
import ReleaseStatusBadge from './ReleaseStatusBadge';
import type { ResultViewProps } from '@elastic/react-search-ui-views/lib/esm/types';
import type { ISnowflakeDataShare } from '../interfaces/IDeployRequest';
import ProjectDeploymentModal from './ProjectDeploymentModal';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectUser, selectSocialFeaturesEnabled } from '../reducers/projectsSlice';
import SetupModal from './SetupModal';
import MarkdownPreview from './MarkdownPreview';
import Button from './Button';
import SocialFeatures from './SocialFeatures';
import { sendMetrics } from '../utilities/analytics';
import MarkdownHelp from './MarkdownHelp';
import { selectDeployments, selectIsReadOnly } from '../reducers/deploymentsSlice';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import { fetchSolution, selectSolution } from '../reducers/solutionsSlice';
import { type RootState } from '../store';
import { fetchUserStars } from '../reducers/accountSlice';
import ProjectCardBody from './ProjectCardBody';

export interface IProjectCardProps extends ResultViewProps {
  result: SearchResult;
  refreshProjects: () => void;
  refreshSolutionDeployments: () => void;
}

const ProjectCard: React.FC<IProjectCardProps> = ({ result, refreshProjects, refreshSolutionDeployments }) => {
  const { token } = useContext<IAuthContext>(AuthContext);
  const dispatch = useAppDispatch();
  const opportunityId = result?.opportunity_id?.raw;
  const dataopsProjectId = result?.dataops_project_id?.raw;
  const projectMainUrl = result?.project_main_url?.raw;
  const releaseStatus = result?.release_status?.raw;
  const version = result?.version?.raw;
  const name = result?.name?.raw;
  const icon = result?.icon?.raw;
  const description = result?.description?.raw;
  const verticalThemes = result?.vertical_themes?.raw;
  // const verticalApplicability = result?.vertical_applicability?.raw;
  const services = result?.services?.raw;
  const verticals = result?.verticals?.raw;
  // const useCase = result?.use_case?.raw;
  const creator = result?.creator?.raw;
  const preview = result?.preview?.raw;
  const help = result?.help?.raw;
  const certified = result?.certified?.raw;
  const preInstanceSetupMessage = result?.pre_instance_setup_message?.raw;
  const preInstanceSetupChecklist = result?.pre_instance_setup_checklist?.raw;
  const requiredDataShares = result?.required_data_shares?.raw;

  const [projectDeploymentsOpen, setProjectDeploymentsOpen] = useState<boolean>(false);
  const [projectSetupOpen, setProjectSetupOpen] = useState<boolean>(false);
  const solution = useAppSelector((state: RootState) => selectSolution(state, dataopsProjectId));
  const deployments = useAppSelector(selectDeployments);
  const username = useAppSelector(selectUser);
  const isReadOnly = useAppSelector(selectIsReadOnly);
  const socialFeaturesEnabled = useAppSelector(selectSocialFeaturesEnabled);
  const cancelButtonRef = useRef(null);

  function extractFullPath(url: string): string {
    // Given example URL: https://app.qa.dataops.live/snowflake/solutions/dataops-qa-project
    // Just extract the last parts of the URL after the `https://app.qa.dataops.live/`.
    const parts = url.split('/');
    return parts.slice(3).join('/');
  }

  useEffect(() => {
    if (token !== '' && projectMainUrl !== undefined) {
      dispatch(fetchSolution({ token, fullPath: extractFullPath(projectMainUrl) })).catch((error) => {
        console.error('Failed to fetch solution:', error);
      });
    }
  }, [token, projectMainUrl]);

  function refreshSolution() {
    if (token !== '') {
      dispatch(fetchUserStars(token)).catch((error) => {
        console.error('Failed to fetch user stars:', error);
      });
    }
    if (token !== '' && projectMainUrl !== undefined) {
      dispatch(fetchSolution({ token, fullPath: extractFullPath(projectMainUrl) })).catch((error) => {
        console.error('Failed to fetch solution:', error);
      });
    }
  }

  const solutionDeployments = deployments.filter((deployment) => {
    return deployment.ciVariables.find(
      (ciVariable) =>
        ciVariable.key === 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID' && ciVariable.value === dataopsProjectId,
    );
  });

  const requiredDataSharesList: ISnowflakeDataShare[] | undefined =
    requiredDataShares !== undefined
      ? requiredDataShares.map((dataShare: string): ISnowflakeDataShare => {
          return JSON.parse(dataShare);
        })
      : undefined;

  const clickSetupNewSolution = () => {
    setProjectSetupOpen(true);
    const analyticsData = {
      event_name: 'dataops-solution-homepage-new-solution-button-click',
      event_source: 'frostbyte-deployment-portal',
      properties: { username, project_name: name, dataops_project_id: dataopsProjectId },
    };
    sendMetrics(analyticsData);
  };

  // function filterTags(projectName: string, tags: string[]): string[] {
  //   if (projectName === 'TastyBytes') {
  //     return tags;
  //   } else if (tags.length > 0) {
  //     return [tags[0]];
  //   } else {
  //     return [];
  //   }
  // }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg hover:bg-sky-50 divide-y">
      <SetupModal
        open={projectSetupOpen}
        setOpen={setProjectSetupOpen}
        cancelButtonRef={cancelButtonRef}
        prefillProjectTitle={name}
        templateId={dataopsProjectId}
        opportunityId={opportunityId}
        preInstanceSetupCheckList={preInstanceSetupChecklist}
        preInstanceSetupMessage={preInstanceSetupMessage}
        requiredDataShares={requiredDataSharesList}
        refreshProjects={refreshProjects}
        setDeploymentsModelOpen={setProjectDeploymentsOpen}
        verticalThemes={verticalThemes !== undefined ? verticalThemes : undefined}
      />
      <ProjectDeploymentModal
        solution={solution}
        open={projectDeploymentsOpen}
        setOpen={setProjectDeploymentsOpen}
        deployments={solutionDeployments}
        refreshProjects={refreshProjects}
        result={result}
        setSetupModalOpen={setProjectSetupOpen}
        refreshSolutionDeployments={refreshSolution}
      />
      <div className="px-4 py-3 sm:px-6 flex flex-row content-center items-center">
        <div className="shrink-0 ">
          <div className="w-8">
            <img src={icon} />
          </div>
        </div>
        <div>
          <h3 className="ml-4 text-xl font-semibold leading-6 text-gray-900 hover:text-sky-500">
            <a href={projectMainUrl} target="_blank" rel="noreferrer">
              {name}
            </a>
          </h3>
        </div>
        {version !== undefined && (
          <div className="ml-2 self-end pb-[4px]">
            <div className="text-sm text-gray-600">{version}</div>
          </div>
        )}
        <div className="flex-1 flex justify-end ml-[auto] items-center space-x-1">
          {socialFeaturesEnabled && (
            <SocialFeatures solution={solution} releaseStatus={releaseStatus} refreshSolution={refreshSolution} />
          )}
          {certified !== undefined && certified === 'true' && <ReleaseStatusBadge label="Certified" />}
          <ReleaseStatusBadge label={releaseStatus} />
          {creator !== '' && <ReleaseStatusBadge label={creator} />}
        </div>
      </div>
      <div className="flex flex-row p-6 space-x-4">
        <ProjectCardBody description={description} verticals={verticals} services={services} />
        <div id="controls" className="basis-60 shrink-0 space-y-2">
          {preview !== undefined && (
            <div className="">
              <dd className="text-sm text-gray-900">
                <MarkdownPreview markdown={preview} />
              </dd>
            </div>
          )}
          <div className="">
            <dd className="text-sm text-gray-900">
              <Button
                id="listing-deployments-button"
                onClick={() => {
                  setProjectDeploymentsOpen(true);
                }}
                intent="light"
                size="large"
                className="w-full"
                data-testid="listing-deployments-button"
              >
                <Cog6ToothIcon className="w-6 h-6 ml-1 mr-2" />
                Deployments ({solutionDeployments.length})
              </Button>
            </dd>
          </div>
          {!isReadOnly && (
            <div className="">
              <Button
                id="setup-new-solution-button"
                onClick={() => {
                  clickSetupNewSolution();
                }}
                intent="primary"
                size="large"
                className="w-full"
              >
                <Cog6ToothIcon className="w-6 h-6 ml-1 mr-2" />
                Setup new solution
              </Button>
            </div>
          )}
          {help !== '' && help !== undefined && (
            <div className="">
              <dd className="text-sm text-gray-900">
                <MarkdownHelp markdown={help} />
              </dd>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

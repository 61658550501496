import { gql } from '@apollo/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../apolloClient';
import { getConfig, resolveShareUrlDomain } from '../config/config-helper';
import axios, { type AxiosError } from 'axios';
import { parseGuid } from '../utilities/parsers';

// #region Interfaces

interface IShareResponse {
  success: boolean;
  message?: string;
  shareCode?: string;
  stepFunctionID?: string;
}

interface ICiVariable {
  key: string;
  value: string;
}

export interface IJob {
  id: string;
  name: string;
  status: string;
}

export interface IPipeline {
  id: string;
  path: string;
  jobs: IJob[];
}

export interface IDeployment {
  id: string;
  guid: string;
  name: string;
  fullPath: string;
  webUrl: string;
  ciVariables: ICiVariable[];
  // --- Extra ---
  requestExtraStatus?: 'empty' | 'filled' | 'loading' | 'failed';
  requestShareUrlStatus?: 'empty' | 'filled' | 'loading' | 'failed';
  latestCommitSha?: string;
  teardownTemplate?: { lastCommitId: string | null };
  validateCi?: { lastCommitId: string | null };
  pipelines?: IPipeline[];
  commitsBehind?: string[];
  upstreamRepoId?: string;
}

interface IDeploymentsState {
  deployments: IDeployment[];
  requestStatus?: 'idle' | 'loading' | 'failed';
}

interface IRepoCommitResponse {
  id: string;
  short_id: string;
  created_at: string;
  parent_ids: string[];
  message: string;
  author_name: string;
  author_email: string;
}

const initialState: IDeploymentsState = {
  deployments: [],
};

const getLatestCommits = async (token: string, url: string, repoId: string): Promise<IRepoCommitResponse[]> => {
  const { data } = await axios.get(`${url}/${encodeURIComponent(repoId)}/repository/commits`, {
    headers: { authorization: `Bearer ${token}` },
  });
  if (data.length === 0) {
    return [];
  }
  return data;
};

const compareCommits = async (token: string, url: string, forkId: string, upstreamId: string) => {
  const forkCommits = await getLatestCommits(token, url, forkId);
  const upstreamCommits = (await getLatestCommits(token, url, upstreamId)).slice(0, 1);
  const forkCommitsIds = forkCommits.map((commit) => commit.id);
  const upstreamCommitsIds = upstreamCommits.map((commit) => commit.id);
  const upstreamCommitsDiff = upstreamCommitsIds.filter((commitId) => !forkCommitsIds.includes(commitId));
  return upstreamCommitsDiff;
};

const getUpstreamRepoId = async (token: string, url: string, forkId: string) => {
  const { data } = await axios.get(`${url}/${encodeURIComponent(forkId)}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  if (data.forked_from_project === null) {
    return undefined;
  }
  return data.forked_from_project?.id;
};

const tryCompareCommits = async (
  token: string,
  url: string,
  forkRepoId: string,
  upstreamRepoId: string | undefined,
) => {
  if (upstreamRepoId === undefined) {
    const upstreamRepoIdLatest = await getUpstreamRepoId(token, url, forkRepoId);
    if (upstreamRepoIdLatest === undefined) {
      throw new Error('Upstream repo not found');
    }
    return [await compareCommits(token, url, forkRepoId, upstreamRepoIdLatest), upstreamRepoIdLatest];
  } else {
    return [await compareCommits(token, url, forkRepoId, upstreamRepoId), upstreamRepoId];
  }
};

const tryGetLatest = async (token: string, fullPath: string): Promise<[IPipeline[], string]> => {
  const GET_LATEST = gql`
    query getLatest {
      project(fullPath: "${fullPath}") {
        repository {
          tree {
            lastCommit {
              sha
            }
          }
        }
        pipelines(ref: "main") {
          nodes {
            id
            path
            jobs {
              nodes {
                id
                name
                status
              }
            }
          }
        }
      }
    }`;
  const deploymentFetchResponse = await client.query({
    query: GET_LATEST,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getLatest' } },
  });
  return [
    deploymentFetchResponse.data.project.pipelines.nodes.map((pipeline: any) => ({
      id: pipeline.id,
      path: pipeline.path,
      jobs: pipeline.jobs.nodes.map((job: any) => ({
        id: job.id,
        name: job.name,
        status: job.status,
      })),
    })),
    deploymentFetchResponse.data.project.repository.tree.lastCommit.sha,
  ];
};

// #region Fetch updates
export const fetchDeploymentUpdates = createAsyncThunk(
  'deployment/fetch/updates',
  async (props: { token: string; deployment: IDeployment }) => {
    const { token, deployment } = props;
    const { dataopsliveBaseUrl } = getConfig();
    const gitlabAPI = `${dataopsliveBaseUrl}/api/v4/projects/`;

    const [commitsDiff, confirmedUpstreamRepoId] = await tryCompareCommits(
      token,
      gitlabAPI,
      deployment.id,
      deployment.upstreamRepoId,
    );

    const [pipelines, lastCommitSha] = await tryGetLatest(token, deployment.fullPath);

    const deploymentUpdates: Partial<IDeployment> = {
      pipelines,
      latestCommitSha: lastCommitSha,
      commitsBehind: commitsDiff,
      upstreamRepoId: confirmedUpstreamRepoId,
    };
    return deploymentUpdates;
  },
);

// #region Fetch extra
export const fetchDeploymentExtra = createAsyncThunk(
  'deployment/fetch/extra',
  async (props: { token: string; fullPath: string; retryCount: number; maxRetry: number }, thunkAPI) => {
    const { token, fullPath } = props;

    const GET_DEPLOYMENT_EXTRA = gql`
    query getDeploymentExtra {
      project(fullPath: "${fullPath}") {
        repository {
          tree {
            lastCommit {
              sha
            }
          }
          teardownTemplate: tree(path: "dataops-teardown/teardown.template.sql") {
            lastCommit {
              id
            }
          }
          validateCi: tree(path: "validate-ci.yml") {
            lastCommit {
              id
            }
          }
        }
        pipelines(ref: "main") {
          nodes {
            id
            path
            jobs {
              nodes {
                id
                name
                status
              }
            }
          }
        }
      }
    }`;
    const deploymentFetchResponse = await client.query({
      query: GET_DEPLOYMENT_EXTRA,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getDeploymentExtra' } },
    });
    const deploymentExtra: Partial<IDeployment> = {
      latestCommitSha: deploymentFetchResponse.data.project.repository.tree.lastCommit.sha,
      teardownTemplate: {
        lastCommitId: deploymentFetchResponse.data.project.repository.teardownTemplate?.lastCommit.id,
      },
      validateCi: { lastCommitId: deploymentFetchResponse.data.project.repository.validateCi?.lastCommit.id },
      pipelines: deploymentFetchResponse.data.project.pipelines.nodes.map((pipeline: any) => ({
        id: pipeline.id,
        path: pipeline.path,
        jobs: pipeline.jobs.nodes.map((job: any) => ({
          id: job.id,
          name: job.name,
          status: job.status,
        })),
      })),
    };
    return deploymentExtra;
  },
);

// #region Fetch deployments
export const fetchDeployments = createAsyncThunk(
  'deployments/fetch/all',
  async (props: { token: string; username: string }): Promise<IDeployment[]> => {
    const { token, username } = props;
    const GET_DEPLOYMENTS = gql`
    query getDeployments {
      group(fullPath: "aws/deployments/${username}") {
        projects {
          nodes {
            id
            name
            fullPath
            webUrl
            ciVariables {
              nodes
              {
                key
                value
              }
            }
          } 
        }
      }
    }
  `;
    const deploymentsFetchResponse = await client.query({
      query: GET_DEPLOYMENTS,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getDeployments' } },
    });

    return deploymentsFetchResponse.data.group.projects.nodes.map((project: any) => ({
      id: parseGuid(project.id),
      guid: project.id,
      name: project.name,
      fullPath: project.fullPath,
      webUrl: project.webUrl,
      ciVariables: project.ciVariables.nodes.map((ciVariable: any) => ({
        key: ciVariable.key,
        value: ciVariable.value,
      })),
      requestExtraStatus: 'empty',
      requestShareUrlStatus: 'empty',
    }));
  },
);

// #region mutate deployment variable
export const mutateDeploymentVariable = createAsyncThunk(
  'deployment/mutate/variable',
  async (props: { token: string; deployment: IDeployment; varKey: string; varValue: string }) => {
    const { token, deployment, varValue, varKey } = props;
    const { dataopsliveBaseUrl } = getConfig();
    const urlBase = `${dataopsliveBaseUrl}/api/v4/projects/${deployment.id}`;
    const requestBody = {
      key: varKey,
      value: varValue,
    };
    try {
      const response = await axios.put(`${urlBase}/variables/${varKey}`, requestBody, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        console.log(`Variable ${varKey} updated to ${varValue}`);
      }
    } catch (error) {
      const data = (error as AxiosError).response?.data as { message: string };
      if (data.message === '404 Variable Not Found') {
        console.log(`Variable ${varKey} not found, creating new variable`);
        try {
          const response2 = await axios.post(`${urlBase}/variables`, requestBody, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response2.status === 201) {
            console.log(`Variable ${varKey} created with value ${varValue}`);
          }
        } catch (error) {
          console.error('Error while updating variable', error);
        }
      } else {
        console.error('Error while updating variable', error);
        throw error;
      }
    }

    return { varKey, varValue };
  },
);

// #region Fetch deployment variables
export const fetchDeploymentVariables = createAsyncThunk(
  'deployment/fetch/variables',
  async (props: { token: string; deployment: IDeployment }) => {
    const { token, deployment } = props;
    const GET_VARIABLES = gql`
    query getVariables {
      project(fullPath: "${deployment.fullPath}") {
        ciVariables {
          nodes
          {
            key
            value
          }
        }
      } 
    }
  `;
    const deploymentsFetchResponse = await client.query({
      query: GET_VARIABLES,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getVariables' } },
    });

    return deploymentsFetchResponse.data.group.projects.nodes.map((project: any) => ({
      ciVariables: project.ciVariables.nodes.map((ciVariable: any) => ({
        key: ciVariable.key,
        value: ciVariable.value,
      })),
    }));
  },
);

// #region mutate deployment share URL
export const mutateDeploymentShareUrl = createAsyncThunk(
  'deployment/mutate/shareUrl',
  async (props: { token: string; deployment: IDeployment }, thunkAPI) => {
    const { token, deployment } = props;
    const { dataopsCreateShareLinkEndpoint, dataopsShareEnvironment } = getConfig();
    try {
      const { data } = await axios.post<IShareResponse>(dataopsCreateShareLinkEndpoint, {
        projectID: deployment.id,
        commit: deployment.latestCommitSha?.split('/').pop(),
        token,
        ignoreFiles: [],
        environment: dataopsShareEnvironment,
        shareExtensions: ['solutionCenter'],
      });
      if (!data.success) {
        const message = 'Share URL not generated: ' + (data.message as string) ?? 'Unknown error';
        return thunkAPI.rejectWithValue(message);
      }
      const domain = resolveShareUrlDomain(dataopsShareEnvironment);
      const shareUrl = `${domain}/${data.shareCode ?? ''}/-/tree`;

      await thunkAPI.dispatch(
        mutateDeploymentVariable({ token, deployment, varKey: 'DATAOPS_CATALOG_SHARE_URL', varValue: shareUrl }),
      );
      return shareUrl;
    } catch (error) {
      console.error('Error while generating share URL', error);
      throw error;
    }
  },
);

// #region Reducer
export const deploymentsSlice = createSlice({
  name: 'deployments',
  initialState,
  reducers: {
    deleteDeployment: (state, action) => {
      state.deployments = state.deployments.filter((d) => d.guid !== action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeployments.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchDeployments.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        state.deployments = action.payload;
        // state.deployments.map((d) => {
        //   const foundDeployment = action.payload.find((nDeployment) => {
        //     if (d.guid === nDeployment.guid) {
        //       return nDeployment;
        //     }
        //     return undefined;
        //   });
        //   if (foundDeployment === undefined) {
        //     return d;
        //   }
        //   return foundDeployment;
        // });
      })
      .addCase(fetchDeployments.rejected, (state) => {
        state.requestStatus = 'failed';
      });
    builder
      .addCase(mutateDeploymentShareUrl.pending, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestShareUrlStatus = 'loading';
        }
      })
      .addCase(mutateDeploymentShareUrl.fulfilled, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestShareUrlStatus = 'filled';
          deployment.ciVariables = deployment.ciVariables.map((ciVariable) => {
            if (ciVariable.key === 'DATAOPS_CATALOG_SHARE_URL') {
              ciVariable.value = action.payload;
            }
            return ciVariable;
          });
        }
      })
      .addCase(mutateDeploymentShareUrl.rejected, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestShareUrlStatus = 'failed';
        }
      });
    builder
      .addCase(fetchDeploymentExtra.pending, (state, action) => {
        const deployment = state.deployments.find((d) => d.fullPath === action.meta.arg.fullPath);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'loading';
        }
      })
      .addCase(fetchDeploymentExtra.fulfilled, (state, action) => {
        const deployment = state.deployments.find((d) => d.fullPath === action.meta.arg.fullPath);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'filled';
          deployment.latestCommitSha = action.payload.latestCommitSha;
          deployment.teardownTemplate = action.payload.teardownTemplate;
          deployment.validateCi = action.payload.validateCi;
          deployment.pipelines = action.payload.pipelines;
        }
      })
      .addCase(fetchDeploymentExtra.rejected, (state, action) => {
        const deployment = state.deployments.find((d) => d.fullPath === action.meta.arg.fullPath);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'failed';
        }
      });
    builder
      .addCase(fetchDeploymentUpdates.pending, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'loading';
        }
      })
      .addCase(fetchDeploymentUpdates.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'filled';
          deployment.commitsBehind = action.payload.commitsBehind;
          deployment.upstreamRepoId = action.payload.upstreamRepoId;
          deployment.pipelines = action.payload.pipelines;
          deployment.latestCommitSha = action.payload.latestCommitSha;
        }
      })
      .addCase(fetchDeploymentUpdates.rejected, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'failed';
        }
      });
    builder
      .addCase(mutateDeploymentVariable.pending, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'loading';
        }
      })
      .addCase(mutateDeploymentVariable.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'filled';
          if (deployment.ciVariables.some((ciVariable) => ciVariable.key === action.payload.varKey)) {
            deployment.ciVariables = deployment.ciVariables.map((ciVariable) => {
              if (ciVariable.key === action.payload.varKey) {
                ciVariable.value = action.payload.varValue;
              }
              return ciVariable;
            });
          } else {
            deployment.ciVariables.push({ key: action.payload.varKey, value: action.payload.varValue });
          }
        }
      })
      .addCase(mutateDeploymentVariable.rejected, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'failed';
        }
      });
    builder
      .addCase(fetchDeploymentVariables.pending, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'loading';
        }
      })
      .addCase(fetchDeploymentVariables.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'filled';
          deployment.ciVariables = action.payload;
        }
      })
      .addCase(fetchDeploymentVariables.rejected, (state, action) => {
        const deployment = state.deployments.find((d) => d.id === action.meta.arg.deployment.id);
        if (deployment !== undefined) {
          deployment.requestExtraStatus = 'failed';
        }
      });
  },
});

export const { deleteDeployment } = deploymentsSlice.actions;

export const selectRequestStatus = (state: { deployments: IDeploymentsState }): string => {
  return state.deployments.requestStatus ?? 'idle';
};
export const selectDeployments = (state: { deployments: IDeploymentsState }): IDeployment[] => {
  return state.deployments.deployments;
};
export const selectDeployment = (state: { deployments: IDeploymentsState }, guid: string): IDeployment | undefined => {
  return state.deployments.deployments.find((d) => d.guid === guid);
};
export const selectIsReadOnly = (state: { deployments: IDeploymentsState }): boolean => {
  if (state.deployments.requestStatus === 'failed') {
    return true;
  }
  return false;
};

export default deploymentsSlice.reducer;

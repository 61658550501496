/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import Button from './Button';
import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import FormField from './FormField';
import { opportunityIdLinkConverter, opportunityIdValidation } from '../utilities/validation';
import { Transition } from '@headlessui/react';
import { AcademicCapIcon, InformationCircleIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { getEnvVariableValue } from '../utilities/parsers';
import { mutateDeploymentVariable, type IDeployment } from '../reducers/deploymentsSlice';
import { useAppDispatch } from '../hooks';

interface HomepageButtonGroupProps {
  onNavigateToSolutionHomepage: (demoPurpose: 'Personal' | 'Customer' | 'LargeAudience') => void;
  projectDeployment: IDeployment;
  opportunityId: string;
  setOpportunityId: (value: string) => void;
  onBack: () => void;
}

export default function HomepageButtonGroup({
  onNavigateToSolutionHomepage,
  projectDeployment,
  opportunityId,
  setOpportunityId,
  onBack,
}: HomepageButtonGroupProps) {
  const { token } = useContext<IAuthContext>(AuthContext);
  const dispatch = useAppDispatch();

  const [opportunityIdErrorMessage, setOpportunityIdErrorMessage] = useState<string>('');
  const [opportunityIdValue, setOpportunityIdValue] = useState<string>(opportunityId);
  const opportunityIdUnsaved = opportunityIdValue !== opportunityId;
  const customerName = getEnvVariableValue(projectDeployment.ciVariables, 'DATAOPS_CATALOG_CUSTOMER_NAME');
  const customerLogoUrl = getEnvVariableValue(projectDeployment.ciVariables, 'FROSTBYTE_CUSTOMER_LOGO_URL');

  useEffect(() => {
    setOpportunityIdErrorMessage(opportunityIdValidation(opportunityIdValue));
  }, [opportunityIdValue]);

  return (
    <div className="mt-5 flex flex-col justify-center space-y-4">
      <div className="text-sm font-medium text-gray-600">This deployment is prepared for:</div>
      <FormField className="w-2/3" label="Customer name" value={customerName} disabled={true}>
        <div className="flex flex-wrap items-center">
          {customerLogoUrl !== '' && (
            <img
              src={customerLogoUrl}
              className="h-10 max-w-full p-1 border rounded-md border-gray-300"
              onError={() => {}}
            />
          )}
        </div>
      </FormField>
      {/* <FormField
        label="Opportunity ID"
        value={opportunityIdValue}
        labelExtra={
          <a
            href="https://snowforce.lightning.force.com/lightning/"
            target="_blank"
            rel="noreferrer"
            className="text-sm hover:underline text-sky-600 ml-2 flex items-center"
          >
            Launch Salesforce
            <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
          </a>
        }
        onChange={(e) => {
          const output = opportunityIdLinkConverter(e.target.value);
          setOpportunityIdErrorMessage(opportunityIdValidation(output));
          setOpportunityId(output);
          setOpportunityIdValue(output);
        }}
        required
      >
        {opportunityIdUnsaved && (
          <div className="flex space-x-2">
            <InformationCircleIcon className="h-5 w-5 text-sky-800" aria-hidden="true" />
            <p className="text-sm font-medium text-sky-800">Changes saved at launch!</p>
          </div>
        )}
        <Transition
          show={opportunityIdErrorMessage !== ''}
          enter="transition duration-[300ms]"
          enterFrom="transform opacity-0 scale-[0.9]"
          enterTo="transform opacity-100 scale-100"
          leave="transition duration-[300ms] ease-in-out"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-[0.9]"
        >
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Validation warning</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>{opportunityIdErrorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </FormField> */}

      <div className="flex flex-row h-36 gap-2">
        <Button
          size="large"
          intent="primary"
          className="flex flex-1 flex-col justify-center"
          onClick={(e) => {
            e.preventDefault();
            // if (opportunityIdErrorMessage === '') {
            onNavigateToSolutionHomepage('Customer');
            // if (opportunityIdUnsaved) {
            //   dispatch(
            //     mutateDeploymentVariable({
            //       token,
            //       deployment: projectDeployment,
            //       varKey: 'DATAOPS_CATALOG_OPPORTUNITY_ID',
            //       varValue: opportunityIdValue,
            //     }),
            //   ).catch(() => {});
            // }
            // }
          }}
        >
          <UserIcon className="w-8 h-8" />
          Customer / Prospect demo
        </Button>
        <Button
          size="large"
          intent="light"
          className="flex flex-1 flex-col justify-center"
          onClick={() => {
            onNavigateToSolutionHomepage('Personal');
          }}
        >
          <AcademicCapIcon className="w-8 h-8" />
          <p>Practice / Personal use</p>
        </Button>
        <Button
          size="large"
          intent="light"
          className="flex flex-1 flex-col justify-center "
          onClick={() => {
            onNavigateToSolutionHomepage('LargeAudience');
          }}
        >
          <UserGroupIcon className="w-8 h-8" />
          Large audience demo
        </Button>
      </div>

      <Button
        size="large"
        intent="subtle"
        className="justify-center"
        onClick={() => {
          onBack();
        }}
      >
        Back
      </Button>
    </div>
  );
}
